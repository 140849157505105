import { withBreakpoints } from "react-breakpoints"

const Breakpointer = ({
  SmallComponent,
  LargeComponent,
  breakpoints,
  currentBreakpoint,
}) => {
  // todo: use custom breakpoint context instead of react-breakpoints
  if (breakpoints[currentBreakpoint] <= breakpoints.mobile) {
    return SmallComponent
  }

  return LargeComponent || null
}

export default withBreakpoints(Breakpointer)
