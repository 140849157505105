import React from "react"
import { Link } from "gatsby"

export default ({ post }) => {
  const url = post.fields.slug
  return (
    <div style={{ marginBottom: "4em" }}>
      <>
        <Title url={url} title={post.frontmatter.title} />
        <div>
          <small>
            {post.frontmatter.date} ({post.timeToRead} minute read)
          </small>
        </div>
        <p>{post.excerpt}</p>
      </>
    </div>
  )
}

const Title = ({ url, title }) => (
  <h2
    css={`
      margin-bottom: 0;
    `}
  >
    <Link
      to={url}
      css={`
        text-decoration: none;
      `}
    >
      {title}
    </Link>
  </h2>
)
