import React from "react"
import Container from "../components/Container"
import H1 from "../components/H1"
import H2 from "../components/H2"
import HeroHeader from "../components/HeroHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import BlogEntry from "./blog-entry"
import Pagination from "./blog-pagination"

export default ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <SEO title="Steven Musumeche's Blog" />
      <HeroHeader textAlign="left">
        <Container>
          <H1>Blog</H1>
          <H2>Sometimes I write things.</H2>
        </Container>
      </HeroHeader>
      <Container className="blog-home">
        {posts.map(post => (
          <BlogEntry key={post.id} post={post} />
        ))}
        <Pagination {...pageContext} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        frontmatter {
          date(formatString: "MMMM Do, YYYY")
          title
        }
        fields {
          slug
        }
        excerpt(pruneLength: 380)
        timeToRead
      }
    }
  }
`
