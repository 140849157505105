import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { withBreakpoints } from "react-breakpoints"
import Breakpointer from "../components/Breakpointer"

const Pagination = ({
  currentPage,
  numPages,
  breakpoints,
  currentBreakpoint,
}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/blog" : `/blog/${currentPage - 1}`
  const nextPage = `/blog/${currentPage + 1}`

  return (
    <PaginationWrapper>
      <div>
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            <Breakpointer SmallComponent="←" LargeComponent="← Previous" />
          </Link>
        )}
      </div>
      <div>
        <Breakpointer
          SmallComponent={`${currentPage}/${numPages}`}
          LargeComponent={`Page ${currentPage} of ${numPages}`}
        />
      </div>
      <div>
        {!isLast && (
          <Link to={nextPage} rel="next">
            <Breakpointer SmallComponent="→" LargeComponent="Next →" />
          </Link>
        )}
      </div>
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default withBreakpoints(Pagination)
